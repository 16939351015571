import d3, { line } from "d3"

/*
Example JENNI (Just Enumerating Nice Nonsense until Intended) script:
col(green)
depth(5)
m(0.5)(1)
a(-90)
f(rf(0.2))
a(rf(-20)(20))
f(rf(0.5))
split(ri(3)(6s))(
    f(rf(0.1))
    push()
    branch(
        a(rf(-5)(5))
        f(rf(0.05))
        leaf(rf(0.3),rf(0.1))
        pop()
    )
    (
        a(rf(-1)(1))
        f(rf(0.5))
        leaf(rf(0.3))
        pop()
    )
)
*/
type d3Sel = d3.Selection<d3.BaseType, any, HTMLElement, any>

enum InstructionType {
    NUMBER,
    RNDINT,
    RNDFLOAT,
    ANGLE,
    FORWARD,
    COLSET,
    SPLIT,
    BRANCH,
    LEAF,
    FLOWER,
    PUSH,
    POP,
    DEPTH
}
enum InstructionGroup {
    EXECUTABLE,
    FUNCTIONAL
}
class Point {
    constructor(public x = 0, public y = 0) { }
    public moveTo(l = 1, a = 0) {
        let new_point = new Point(this.x, this.y)
        new_point.x += l * Math.cos(Math.PI * a / 180)
        new_point.y += l * Math.sin(Math.PI * a / 180)
        return new_point
    }
    public minus(other: Point) {
        return new Point(this.x - other.x, this.y - other.y)
    }
    public plus(other: Point) {
        return new Point(this.x + other.x, this.y + other.y)
    }
}

class ExecutionContext {
    static height = 1000
    static width = 1000
    static states: ExecutionContext[] = []

    constructor(public pos = new Point(), public angle = 0, public svg: d3Sel, public color = 'darkgreen', public max_depth = 7, public depth = 7, public cntr = 0, private instructions: ExecutionalInstruction[] = []) {

    }
    public execute(instrs: ExecutionalInstruction[] = null, depth = this.depth) {
        this.depth = depth
        if (instrs) {
            this.cntr = 0;
            this.instructions = [...instrs]
        }
        for (; this.cntr < this.instructions.length; this.cntr++) {
            this.instructions[this.cntr].execute(this);
        }
    }
    public project(p: Point) {
        let new_point = new Point(p.x, p.y)
        new_point.x = p.x * ExecutionContext.width
        new_point.y = p.y * ExecutionContext.height
        return new_point
    }
    public copy() {
        return new ExecutionContext(new Point(this.pos.x, this.pos.y), this.angle, this.svg, this.color, this.max_depth, this.depth, this.cntr, this.instructions)
    }

}
abstract class Instruction {
    constructor(public code: string, public type: InstructionType, public group: InstructionGroup) { }

}
abstract class ExecutionalInstruction extends Instruction {
    constructor(public code: string, public type: InstructionType) {
        super(code, type, InstructionGroup.EXECUTABLE)
    }
    public execute(ctx: ExecutionContext): ExecutionContext {
        return ctx
    }
}
abstract class FunctionalInstruction extends Instruction {
    constructor(public code: string, public type: InstructionType) {
        super(code, type, InstructionGroup.FUNCTIONAL)
    }
    public eval(ctx: ExecutionContext): any {
        return ctx
    }
}
class Number extends FunctionalInstruction {
    value: number = 0
    constructor(public code: string, arg: string) {
        super(code, InstructionType.NUMBER)
        this.value = parseFloat(arg)
    }
    public eval(ctx: ExecutionContext): number {
        return this.value
    }
}
class RandomInt extends FunctionalInstruction {
    lower: number = 0
    upper: number = 0
    constructor(public code: string, arg1: string, arg2: string = null) {
        super(code, InstructionType.RNDINT)
        if (arg2) {
            this.lower = parseInt(arg1)
            this.upper = parseInt(arg2)
        } else {
            this.upper = parseInt(arg1)
        }
    }
    public eval(ctx: ExecutionContext): number {
        return Math.floor(Math.random() * (this.upper - this.lower)) + this.lower
    }
}
class RandomFloat extends FunctionalInstruction {
    lower: number = 0
    upper: number = 0
    constructor(public code: string, arg1: string, arg2: string = null) {
        super(code, InstructionType.RNDINT)
        if (arg2) {
            this.lower = parseFloat(arg1)
            this.upper = parseFloat(arg2)
        } else {
            this.upper = parseFloat(arg1)
        }
    }
    public eval(ctx: ExecutionContext): number {
        return Math.random() * (this.upper - this.lower) + this.lower
    }
}
class ExponentialDepthDecay extends FunctionalInstruction {
    constructor(public code: string, public number: FunctionalInstruction) {
        super(code, InstructionType.RNDINT)
    }
    public eval(ctx: ExecutionContext): number {

        return Math.exp(-(ctx.max_depth - ctx.depth) / 2) * this.number.eval(ctx)
    }
}


class AngleInstruction extends ExecutionalInstruction {
    arg: FunctionalInstruction
    constructor(public code: string, arg: FunctionalInstruction) {
        super(code, InstructionType.ANGLE)
        this.arg = arg
    }

    public execute(ctx: ExecutionContext): ExecutionContext {
        ctx.angle += this.arg.eval(ctx)
        return ctx
    }
}

class ColorSetInstruction extends ExecutionalInstruction {
    arg: string
    constructor(public code: string, arg: string) {
        super(code, InstructionType.ANGLE)
        this.arg = arg
    }

    public execute(ctx: ExecutionContext): ExecutionContext {
        ctx.color = this.arg
        return ctx
    }
}
class MoveInstruction extends ExecutionalInstruction {
    constructor(public code: string, public x: FunctionalInstruction, public y: FunctionalInstruction) {
        super(code, InstructionType.ANGLE)

    }
    public execute(ctx: ExecutionContext): ExecutionContext {
        ctx.pos = new Point(this.x.eval(ctx), this.y.eval(ctx))
        return ctx
    }
}

class ForwardInstruction extends ExecutionalInstruction {
    length: FunctionalInstruction
    constructor(public code: string, arg: FunctionalInstruction) {
        super(code, InstructionType.ANGLE)
        this.length = arg
    }

    public execute(ctx: ExecutionContext): ExecutionContext {
        let start = ctx.project(ctx.pos)
        let end_plane = ctx.pos.moveTo(this.length.eval(ctx), ctx.angle)
        let end = ctx.project(end_plane)
        let data = [start, end].map(p => { return [p.x, p.y] })
        ctx.pos = end_plane
        ctx.svg.append('path')
            .attr('d', line()(data as any))
            .attr("stroke", ctx.color)
            .attr("stroke-width", 1)
        return ctx
    }
}

class SplitInstruction extends ExecutionalInstruction {
    constructor(public code: string, public number: FunctionalInstruction, public instructions: ExecutionalInstruction[]) {
        super(code, InstructionType.SPLIT)
    }

    public execute(ctx: ExecutionContext): ExecutionContext {
        let number = this.number.eval(ctx)
        for (let index = 0; index < number; index++) {
            let local_ctx = ctx.copy()
            local_ctx.execute(this.instructions)
        }
        return ctx
    }
}

class BranchInstruction extends ExecutionalInstruction {
    constructor(public code: string, public branches: ExecutionalInstruction[][]) {
        super(code, InstructionType.BRANCH)
    }

    public execute(ctx: ExecutionContext): ExecutionContext {
        for (const branch of this.branches) {
            let local_ctx = ctx.copy()
            local_ctx.execute(branch)
        }
        return ctx
    }
}

class PushInstruction extends ExecutionalInstruction {
    constructor(public code: string) {
        super(code, InstructionType.PUSH)
    }

    public execute(ctx: ExecutionContext): ExecutionContext {
        let saved_ctx = ctx.copy()
        saved_ctx.cntr++
        ExecutionContext.states.push(saved_ctx)
        return ctx
    }
}
class PopInstruction extends ExecutionalInstruction {
    constructor(public code: string, public number: FunctionalInstruction) {
        super(code, InstructionType.PUSH)
    }

    public execute(ctx: ExecutionContext): ExecutionContext {
        let how_far = this.number.eval(ctx) % ExecutionContext.states.length
        let continue_context = ExecutionContext.states[how_far].copy()

        continue_context.pos = new Point(ctx.pos.x, ctx.pos.y)
        continue_context.angle = ctx.angle
        if (ctx.depth > 0) {
            continue_context.execute(null, ctx.depth - 1)
        }

        return ctx
    }
}
class DepthInstruction extends ExecutionalInstruction {
    constructor(public code: string, public number: FunctionalInstruction) {
        super(code, InstructionType.DEPTH)
    }

    public execute(ctx: ExecutionContext): ExecutionContext {
        ctx.depth = this.number.eval(ctx)
        ctx.max_depth = ctx.depth
        return ctx
    }
}
class LeafInstruction extends ExecutionalInstruction {
    constructor(public code: string, public length: FunctionalInstruction, public width: FunctionalInstruction | null) {
        super(code, InstructionType.DEPTH)
    }

    public execute(ctx: ExecutionContext): ExecutionContext {
        let length = this.length.eval(ctx)
        let width = this.width ? this.width.eval(ctx) : length / 3
        let start = ctx.project(ctx.pos)

        let midpoint = ctx.pos.moveTo(length * 2 / (1 + Math.sqrt(5)), ctx.angle) // golden ratio
        let leftpt = ctx.project(midpoint.moveTo(width / 2, ctx.angle - 90))
        let rightpt = ctx.project(midpoint.moveTo(width / 2, ctx.angle + 90))

        let end_plane = ctx.pos.moveTo(length, ctx.angle)
        let end = ctx.project(end_plane)
        let data = [start, leftpt, end, rightpt, start].map(p => { return [p.x, p.y] })
        ctx.svg.append('path')
            .attr('d', line()(data as any))
            .attr("stroke", 'black')
            .attr("fill", ctx.color)
        return ctx
    }
}
class Parser {
    constructor() {

    }
    private static parseAndCheckFunctional(instr: string, code: string, args: string[], expected = 1, expected_max = expected) {
        if (args.length < expected || args.length > expected_max) {
            throw Error(`Too many instructions for ${instr} ${code}`)
        }
        let functional_args = args.map(Parser.parse).map(arg => {
            if (arg.length != 1) {
                throw Error(`Too many instructions in arg for ${instr} ${code}`)
            }
            if (arg[0].group != InstructionGroup.FUNCTIONAL) {
                throw Error(`Too many instructions for ${instr} ${code}`)
            }
            return arg[0] as FunctionalInstruction
        })
        return functional_args
    }
    public static parse(code: string): Instruction[] {
        // 2. find instructions by ungreedy splitting and searching
        // 3. determine type and recursively check for sub-instructions

        let remaining_code = code
        let instructions: Instruction[] = []
        while (remaining_code.trim().length > 0) {
            let matches = /^[ \n]*([A-Za-z]\w*)\((.*)\)/gs.exec(remaining_code.trim())

            if (matches) {
                let instruction = matches[1].trim()
                let depth = 0
                let args: string[] = []
                let start_pos = -1
                let found_all_args = false
                let i = instruction.length
                for (; i < remaining_code.length && !found_all_args; i++) {
                    if (remaining_code[i] == '(') {
                        if (depth == 0) {
                            start_pos = i + 1
                        }
                        depth++
                    } else if (remaining_code[i] == ')') {
                        depth--
                        if (depth == 0) {
                            args.push(remaining_code.substring(start_pos, i))
                            let next_open = /^[ \n]*\(/g.exec(remaining_code.substring(i + 1))
                            if (next_open) {
                                start_pos = -1
                                i += next_open[0].length - 1
                            } else {
                                found_all_args = true
                            }
                        }
                    }
                }
                let instr_code = remaining_code.substring(0, i).trim()
                switch (instruction) {
                    case 'ri':
                        if (args.length > 2) {
                            throw Error("Too many instructions for Random Int: " + remaining_code)
                        }
                        instructions.push(new RandomInt(instr_code, args[0], args.length > 1 ? args[1] : null))
                        break;

                    case 'rf':
                        if (args.length > 2) {
                            throw Error("Too many instructions for Random Float: " + remaining_code)
                        }
                        instructions.push(new RandomFloat(instr_code, args[0], args.length > 1 ? args[1] : null))
                        break;
                    case 'e':
                    case 'ed':
                        {

                            let fun_args = Parser.parseAndCheckFunctional(instruction, instr_code, args, 1)
                            instructions.push(new ExponentialDepthDecay(instr_code, fun_args[0]))
                        }
                        break;
                    case 'col':
                        if (args.length > 1) {
                            throw Error("Too many instructions for Color set: " + remaining_code)
                        }
                        instructions.push(new ColorSetInstruction(instr_code, args[0]))
                        break;
                    case 'a':
                    case 'angle':
                        {
                            let fun_args = Parser.parseAndCheckFunctional(instruction, instr_code, args, 1)
                            instructions.push(new AngleInstruction(instr_code, fun_args[0]))
                        }
                        break;
                    case 'm':
                    case 'mov':
                    case 'move':
                        {
                            let fun_args = Parser.parseAndCheckFunctional(instruction, instr_code, args, 2)
                            instructions.push(new MoveInstruction(instr_code, fun_args[0], fun_args[1]))
                        }
                        break;
                    case 'f':
                        {
                            let fun_args = Parser.parseAndCheckFunctional(instruction, instr_code, args, 1)
                            instructions.push(new ForwardInstruction(instr_code, fun_args[0]))
                        }
                        break;
                    case 'l':
                    case 'leaf':
                        {
                            let fun_args = Parser.parseAndCheckFunctional(instruction, instr_code, args, 1, 2)
                            if (fun_args.length < 2) {
                                fun_args.push(null)

                            }
                            instructions.push(new LeafInstruction(instr_code, fun_args[0], fun_args[1]))
                        }
                        break;
                    case 'split':
                        if (args.length != 2) {
                            throw Error("False number of instr for split: " + remaining_code)
                        }
                        {
                            let fun_args = Parser.parseAndCheckFunctional(instruction, instr_code, [args[0]], 1)
                            let exec_args = Parser.parse(args[1])
                            instructions.push(new SplitInstruction(instr_code, fun_args[0], exec_args as ExecutionalInstruction[]))
                        }
                        break;

                    case 'branch':
                        if (args.length < 2) {
                            throw Error("False number of instr for branch: " + remaining_code)
                        }
                        {
                            let exec_args = args.map(this.parse)
                            instructions.push(new BranchInstruction(instr_code, exec_args as ExecutionalInstruction[][]))
                        }
                        break;
                    case 'push':
                        if (args.length != 1) {
                            throw Error("False number of instr for push: " + remaining_code)
                        }
                        {
                            instructions.push(new PushInstruction(instr_code))
                        }
                        break;
                    case 'pop':
                        if (args.length != 1) {
                            throw Error("False number of instr for push: " + remaining_code)
                        }
                        {
                            let fun_args = new Number('1', '1') as FunctionalInstruction
                            if (args[0].trim().length == 0) {
                                args.pop()
                            } else {
                                fun_args = Parser.parseAndCheckFunctional(instruction, instr_code, args, 1)[0]
                            }
                            instructions.push(new PopInstruction(instr_code, fun_args))
                        }
                        break;
                    case 'depth':
                        {
                            let fun_args = Parser.parseAndCheckFunctional(instruction, instr_code, args, 1)
                            instructions.push(new DepthInstruction(instr_code, fun_args[0]))
                        }
                        break;
                }
                remaining_code = remaining_code.substring(i)
            } else if (/^[ \n]*-?\d+\.?\d*[ \n]*$/gm.test(remaining_code)) {
                instructions.push(new Number(remaining_code, remaining_code))
                remaining_code = ''
            } else {
                throw Error("You probably have a syntax error somewhere around: " + remaining_code)
            }
        }
        return instructions
    }
}
export class Interpreter {
    version: number = 1
    code: string = ""
    parser: Parser
    element: d3Sel
    constructor(code: string = "", d3obj: d3Sel) {
        this.code = code
        this.element = d3obj
    }
    public execute() {
        ExecutionContext.states=[]
        let base_ctx = new ExecutionContext(new Point(), 0, this.element)
        let instructions = Parser.parse(this.code)
        base_ctx.execute(instructions as ExecutionalInstruction[])
    }

}
