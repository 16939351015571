<template>
  <div class="wrapper full">
    <div class="top">
      <div class="header">Will you be my Valentine?</div>
    </div>
    <div class="content">
      <div class="content-left">
        <div class="flower svg-container">
          <svg
            preserveAspectRatio="xMinYMin meet"
            :viewBox="state.viewBox"
          ></svg>
        </div>
      </div>
      <div class="content-right">
        <div class="q-row">
          <div class="q-l">FROM:</div>
          <div class="q-r">BENEDIKT</div>
        </div>
        <div class="q-row">
          <div class="q-l">TO:</div>
          <div class="q-r">JENNI</div>
        </div>
      </div>
    </div>
  </div>
  <div style="height: 30vh"></div>
  <div class="options">
    <div class="o-row">
      <div class="o-l">
        Toogle Regeneration Looping: {{ state.looping ? "ON" : "OFF" }}
      </div>
      <div class="o-r">
        <button @click="() => (state.looping = !state.looping)">Toggle</button>
      </div>
    </div>
    <div class="o-row">
      <div class="o-l">JENNI-Script:</div>
      <div class="o-r">
        <textarea v-model="state.code"></textarea>
      </div>
    </div>
    <div class="o-row">
      <div class="o-l">Samples:</div>
      <div class="o-r">
        <button
          v-for="(value, name) in state.samples"
          :key="name"
          @click="state.code = value"
        >
          {{ name }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import * as d3 from "d3";
import { Interpreter } from "../scripts/interpreter";
import { reactive, computed, onMounted, watch } from "vue";

export default {
  setup() {
    const state = reactive({
      height: 1000,
      width: 1000,
      code: "",
      looping: true,
      loopInterval: 3000,
      timer: -1,
      viewBox: computed(() => `0 0 ${state.width} ${state.height}`),
      samples: {
        flowers: `
col(darkgreen)
depth(ri(1)(3))
m(0.5)(1)
a(-90)
f(rf(0.1))
a(rf(-10)(10))
push()
split(ri(3)(4))(
    f(rf(0.1))
    branch(
        a(rf(-20)(40))
        f(e(0.1))
        pop()
        leaf(e(0.1))
    )
    (
        a(rf(-15)(15))
        f(e(0.3))
        pop()
        col(red)
        leaf(e(0.1))
        a(-45)
        leaf(e(0.1))
        a(-45)
        leaf(e(0.1))
        a(-45)
        leaf(e(0.1))
        a(-45)
        leaf(e(0.1))
        a(-45)
        leaf(e(0.1))
        a(-45)
        leaf(e(0.1))
        a(-45)
        leaf(e(0.1))
    )
)
`,
        tree: `
col(green)
depth(5)
m(0.5)(1)
a(-90)
f(0.2)
push()
branch(
    a(-45)
    f(ed(0.2))
    pop()
    leaf(ed(0.2))
)
(
    a(45)
    f(ed(0.2))
    pop()
    leaf(ed(0.2))
)
    `,
        experiment: `
col(darkgreen)
depth(ri(1)(3))
m(0.5)(1)
a(-90)
f(rf(0.1))
a(rf(-10)(10))
push()
split(ri(2)(3))(
    f(rf(0.1))
    branch(
        a(rf(-20)(40))
        f(e(0.1))
        pop()
        leaf(e(0.1))
    )
    (
        a(rf(-15)(15))
        f(e(0.3))
        pop()
        col(red)
        leaf(e(0.1))
        a(-45)
        leaf(e(0.1))
        a(-45)
        leaf(e(0.1))
        a(-45)
        leaf(e(0.1))
        a(-45)
        leaf(e(0.1))
        a(-45)
        leaf(e(0.1))
        a(-45)
        leaf(e(0.1))
        a(-45)
        leaf(e(0.1))
    )(
        a(rf(-15)(15))
        f(e(0.3))
        pop()
        col(orange)
        leaf(e(0.05))
        a(-45)
        leaf(e(0.05))
        a(-45)
        leaf(e(0.05))
        a(-45)
        leaf(e(0.05))
        a(-45)
        leaf(e(0.05))
        a(-45)
        leaf(e(0.05))
        a(-45)
        leaf(e(0.05))
        a(-45)
        leaf(e(0.05))
    )
)
    `,
      },
    });
    const d3l=d3
    function updater() {
      console.log(d3, d3l);
      var svg = d3.select("svg").classed("svg-content", true);
      svg.selectAll("*").remove();
      let interpreter = new Interpreter(state.code, svg);
      interpreter.execute();
    }
    watch(
      () => state.code,
      () => {
        updater();
      },
      {
        immediate: true,
      }
    );
    clearInterval(state.timer);
    watch(
      () => state.looping,
      () => {
        console.log("Switching...", state.looping);
        clearInterval(state.timer);

        if (state.looping) {
          state.timer = setInterval(updater, state.loopInterval);
        }
      },
      {
        immediate: true,
      }
    );
    onMounted(() => {
      state.code = state.samples.experiment;
      updater();
    });

    return {
      state,
    };
  },
};
</script>